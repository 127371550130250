export function getContactFormEquityRangeText (range, currencySymbol, twoDigitLocale) {
  if (!range) return null

  const captionStart = 'Bis '
  const locale = twoDigitLocale.toLowerCase() + '-DE'
  let result = ''

  // Werte aus dem alten und neuen Formular kombiniert
  switch (range) {
    case -1:
      result = 'Keine Angabe'
      break
    case 10:
      result = captionStart + getCurrencyString(2500, currencySymbol, locale)
      break
    case 20:
      result = captionStart + getCurrencyString(5000, currencySymbol, locale)
      break
    case 30:
      result = captionStart + getCurrencyString(10000, currencySymbol, locale)
      break
    case 40:
      result = captionStart + getCurrencyString(25000, currencySymbol, locale)
      break
    case 50:
      result = captionStart + getCurrencyString(50000, currencySymbol, locale)
      break
    case 60:
      result = captionStart + getCurrencyString(50000, currencySymbol, locale)
      break
    case 70:
      result = captionStart + getCurrencyString(75000, currencySymbol, locale)
      break
    case 80:
      result = captionStart + getCurrencyString(100000, currencySymbol, locale)
      break
    case 90:
      result = '> ' + getCurrencyString(100000, currencySymbol, locale)
      break
    case 100:
      result = '> ' + getCurrencyString(100000, currencySymbol, locale)
      break
    default:
      result = range
      break
  }

  return result
}

export function getCurrencyString (value, currencySymbol, locale) {
  const formatter = new Intl.NumberFormat(locale, { style: 'currency', currency: currencySymbol })
  return formatter.format(value).replace(/,00/, '')
}
