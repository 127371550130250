<template>
  <div id="leads" class="mb-main-content" v-if="hasCurrentBrand">
    <h1>{{ $t("menue.leads") }}</h1>

    <div class="d-flex flex-nowrap">
      <div class="col-12 col-md-6 filters flex-shrink-1">
        <div class="d-flex flex-column">
          <div class="d-flex main">
            <b-dropdown v-if="0" :text="computedCurrentLeadTypeLabel" class="m-md-2">
              <b-dropdown-item v-for="leadType in computedLeadTypes" :key="leadType.key"
                :active="computedCurrentLeadTypeIsActive(leadType.key)" @click="handleLeadTypeChange(leadType.key)">{{
                  leadType.label }}</b-dropdown-item>
            </b-dropdown>
            <a v-if="0" href="javascript:" @click="openFilterWindow">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M14.6667 2H1.33337L6.66671 8.30667V12.6667L9.33337 14V8.30667L14.6667 2Z" stroke="#00719F"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <span>{{ $t("page.contactrequests.filter") }}</span>
            </a>

            <div class="d-flex flex-column gap-3 flex-wrap">
              <label for="dateInputFrom" class="fw-bold">{{ $t("page.contactrequests.filterDates") }}</label>
              <div class="d-flex gap-3 align-items-center flex-wrap">
                <DatePicker :input-attr="{ id: 'dateInputFrom' }" v-model="filters.startDate" :placeholder="$t('page.contactrequests.from')"
                  format="DD.MM.YYYY" value-type="YYYY-MM-DD" />
                <span>{{ $t('page.contactrequests.toCaption') }}</span>
                <DatePicker v-model="filters.endDate" :placeholder="$t('page.contactrequests.to')" format="DD.MM.YYYY" value-type="YYYY-MM-DD" />
                <button class="btn btn-sm" @click="applyFilters"
                  :disabled="!computedApplyFiltersActive">{{ $t('page.contactrequests.apply') }}</button>
              </div>
            </div>

          </div>
          <div class="active-filters" v-if="0">
            <a href="javascript:" class="active-filter" v-if="computedHasActiveEquityFilter"
              @click="removeEquityFilter">
              <inline-svg :src="require('@/assets/images/icons/3.0/close.svg')" />
              <span>Eigenkapital: {{ filters.equity[0] }} € bis {{ filters.equity[1] }} €</span>
            </a>
            <a href="javascript:" class="active-filter" v-if="computedHasActiveMatchScoreFilter"
              @click="removeMatchScoreFilter">
              <inline-svg :src="require('@/assets/images/icons/3.0/close.svg')" />
              <span>MatchScore: {{ filters.matchScore[0] }} % bis {{ filters.matchScore[1] }} %</span>
            </a>
          </div>

          <b-modal id="filter-modal" title="Meine Leads filtern" size="lg">
            <h2>Welche Kriterien sollen Gründer*innen erfüllen?</h2>

            <div class="filter-selects">

              <div class="filter">

                <div class="headline">
                  <h3>Vorhandenes Eigenkapital</h3>
                </div>

                <div class="filter-value">
                  <no-ui-slider :start="filters.equity" :range="{ min: 0, max: 250000 }" :step="5000" :connect="true"
                    @update:value="updateValueEquityFilter"></no-ui-slider>
                </div>

                <div class="filter-inputs">
                  <div class="input-wrapper">
                    <input type="text" v-model="filters.equity[0]" />
                    <span>Minimum</span>
                  </div>
                  <span>bis</span>
                  <div class="input-wrapper">
                    <input type="text" v-model="filters.equity[1]" />
                    <span>Maximum</span>
                  </div>
                </div>

              </div>

              <div class="filter">

                <div class="headline">
                  <h3>Berechneter MatchScore</h3>
                </div>

                <div class="filter-value">
                  <no-ui-slider :start="filters.matchScore" :range="{ min: 0, max: 100 }" :step="10" :connect="true"
                    @update:value="updateValueMatchScore"></no-ui-slider>
                </div>

                <div class="filter-inputs">
                  <div class="input-wrapper">
                    <input type="text" v-model="filters.matchScore[0]" />
                    <span>Minimum</span>
                  </div>
                  <span>bis</span>
                  <div class="input-wrapper">
                    <input type="text" v-model="filters.matchScore[1]" />
                    <span>Maximum</span>
                  </div>
                </div>

              </div>
            </div>

            <template #modal-footer>
              <button @click="resetFilters">Alles zurücksetzen</button>
              <button @click="applyFilters">Passende Gründer*innen anzeigen</button>
            </template>

          </b-modal>
        </div>
      </div>

      <div class="col-12 col-md-6 filters">
        <div class="d-flex justify-content-between align-items-center w-100">
          <strong v-t="'page.contactrequests.apikeytitle'"></strong>
          <span class="mb-zapier-logo">
            <img src="../../assets/images/icons/zapier.png" />
          </span>
        </div>
        <small v-html="$t('page.contactrequests.apikeydesc', { zapierapp: 'https://zapier.com/apps/franchiseuniversum' })">
        </small>
        <div class="d-flex gap-3 w-100">
          <input class="form-control text-center" type="text" :value="computedApiKey" readonly>
          <button class="btn btn-sm" @click="createAPIKeyIfNotExists(); showApiKey = !showApiKey">{{ computedApiKeyBtnText }}</button>
        </div>
        <p>
          <a href="https://youtu.be/F0Woa0CihnU" v-t="'page.contactrequests.apikeyvideo'"
            target="_blank"></a>
        </p>
      </div>
    </div>

    <div class="sorting-row d-flex justify-content-end">

      <a href="javascript:" @click="exportLeads">
        <inline-svg width="16" height="16" :src="require('@/assets/images/icons/3.0/export.svg')" />
        {{ computedExportBtnText }}
      </a>
    </div>

    <template v-if="isLoading">
      {{ $t('page.contactrequests.loadingcontacts') }}
    </template>
    <template v-else>
      <p v-if="brandStore.getCurrentLeads.length === 0">
        {{ $t('page.contactrequests.nocontacts') }}
      </p>
      <div v-else class="d-flex">
        <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false" />
        <LeadItem v-for="currentLead in brandStore.getCurrentLeads" :key="currentLead.LeadID"
          :currentLead="currentLead" :isLeadPage="true" />
      </div>
    </template>

    <div v-if="hasMoreLeadsToLoad" class="d-flex justify-content-center mt-4">
      <button class="btn btn-primary" @click="loadLeads">
        {{ $t('button.loadMore') }}
      </button>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import InlineSvg from 'vue-inline-svg'
import LeadItem from '@/components/3.0/LeadItem.vue'
import { usePageStore } from '@/stores/pageStore'
import { useBrandStore } from '@/stores/brandStore'
import { useChatStore } from '@/stores/chatStore'
import NoUiSlider from '@/components/3.0/NoUiSlider.vue'
import Loading from 'vue-loading-overlay'
import DatePicker from 'vue2-datepicker'
import Config from '../../config'

const leadTypes = [
  { key: 'all', label: 'Alle Kontakte' },
  { key: 'marked', label: 'Gemerkte Kontakte' }
]

export default {
  components: {
    InlineSvg,
    LeadItem,
    NoUiSlider,
    Loading,
    DatePicker
  },
  name: 'LeadsOverview',
  inject: ['API', 'APIService', 'GoogleMap'],
  data: function () {
    return {
      id: null,
      pageStore: usePageStore(),
      brandStore: useBrandStore(),
      chatStore: useChatStore(),
      isLoading: false,
      showApiKey: false,
      filters: {
        leadType: 'all',
        equity: [0, 250000],
        matchScore: [0, 100],
        startDate: undefined,
        endDate: undefined
      }
    }
  },
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      return this.brandStore.getCurrentBrand
    },
    hasCurrentBrand: function () {
      return this.brandStore.getCurrentBrand !== null
    },
    computedApplyFiltersActive () {
      return this.filters.startDate && this.filters.endDate
    },
    computedHasActiveEquityFilter () {
      return parseInt(this.filters.equity[0]) > 0 || parseInt(this.filters.equity[1]) < 250000
    },
    computedHasActiveLeadTypeFilter () {
      return this.filters.leadType !== 'all'
    },
    computedHasActiveMatchScoreFilter () {
      return parseInt(this.filters.matchScore[0]) > 0 || parseInt(this.filters.matchScore[1]) < 100
    },
    computedHasActiveFilters () {
      return (
        this.computedHasActiveEquityFilter ||
        this.computedHasActiveMatchScoreFilter ||
        this.computedHasActiveLeadTypeFilter
      )
    },
    hasMoreLeadsToLoad () {
      return this.brandStore.getCurrentLeadsCount > this.brandStore.getCurrentLeads.length
    },
    computedLeadTypes () {
      return leadTypes
    },
    computedCurrentLeadType () {
      return this.filters.leadType
    },
    computedCurrentLeadTypeLabel () {
      return leadTypes.find(leadType => leadType.key === this.computedCurrentLeadType).label
    },
    computedCurrentLeadTypeIsActive () {
      return (key) => {
        return this.computedCurrentLeadType === key
      }
    },
    computedFilterArray () {
      const activeFilters = []

      if (this.computedHasActiveEquityFilter) {
        activeFilters.push({
          Field: 'Equity',
          Value: this.filters.equity
        })
      }

      if (this.filters.startDate && this.filters.endDate) {
        activeFilters.push({
          Field: 'Date',
          Value: [this.filters.startDate, this.filters.endDate]
        })
      }

      return activeFilters
    },
    computedApiKey () {
      return this.showApiKey ? this.currentBrand.APIKey : 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
    },
    computedApiKeyBtnText () {
      return this.showApiKey ? this.$t('page.contactrequests.apikeyhide') : this.$t('page.contactrequests.apikeyshow')
    },
    computedExportBtnText () {
      if (this.filters.startDate && this.filters.endDate) {
        return this.$t('button.exportselection')
      }
      return this.$t('button.exportall')
    }
  },
  created: function () { },
  methods: {
    exportLeads () {
      this.pageStore.startLoading('LeadExport')
      this.APIService._post('leads/export', {
        BrandID: this.currentBrand.BrandID,
        From: this.filters.startDate ? this.filters.startDate : undefined,
        To: this.filters.endDate ? this.filters.endDate : undefined
      })
        .then(response => {
          const FileName = response.data.FileName
          const DownloadURL = `${Config.FPSERVICE_URL}leads/export/download/${FileName}`
          window.open(DownloadURL)
        })
        .catch(() => {
          this.$swal(this.$t('popupmessage.error.title'), this.$t('popupmessage.error.exporterror'), 'error')
        })
        .finally(() => {
          this.pageStore.stopLoading('LeadExport')
        })
    },
    removeEquityFilter () {
      this.filters.equity = [0, 250000]
    },
    removeMatchScoreFilter () {
      this.filters.matchScore = [0, 100]
    },
    resetFilters () {
      this.filters.equity = [0, 250000]
      this.filters.matchScore = [0, 100]
      this.filters.leadType = 'all'
    },
    applyFilters () {
      this.$bvModal.hide('filter-modal')
      this.brandStore.clearCurrentLeads()
      this.loadLeads()
    },
    openFilterWindow () {
      this.$bvModal.show('filter-modal')
    },
    updateValueEquityFilter (value) {
      this.filters.equity = value
    },
    updateValueMatchScore (value) {
      this.filters.matchScore = value
    },
    handleLeadTypeChange (leadTypeKey) {
      this.filters.leadType = leadTypeKey
    },
    async loadLeads () {
      if (this.currentBrand === null) return
      try {
        this.isLoading = true

        const currentLeadCount = this.brandStore.getCurrentLeads.length

        const loadLeadsRequestBody = {
          ResFrom: currentLeadCount + 1,
          ResTo: currentLeadCount + 20,
          Filters: this.computedFilterArray,
          BrandID: this.currentBrand.BrandID
        }

        if (typeof this.$route.params.id !== 'undefined') {
          const leadId = parseInt(this.$route.params.id)
          if (leadId) {
            loadLeadsRequestBody.LeadID = leadId
          }
        }

        const loadedLeadsResponse = await this.APIService._post('leads', loadLeadsRequestBody)

        if (loadedLeadsResponse.body.Result.Data.length === 0) {
          this.isLoading = false
          return
        }

        this.brandStore.addCurrentLeads(loadedLeadsResponse.body.Result.Data)
        this.brandStore.setCurrentLeadsCount(loadedLeadsResponse.body.Result.RecordCount)
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    createAPIKeyIfNotExists () {
      if (this.currentBrand && this.currentBrand.APIKey === null) {
        this.currentBrand.APIKey = Vue.Utils.uuidv4()
        this.app.saveBrand()
      }
    }
  },
  watch: {
    async currentBrand (newBrand) {
      if (newBrand === null) return
      await this.loadLeads()
      await this.chatStore.loadMessages()
      this.createAPIKeyIfNotExists()
    }
  },
  mounted: async function () {
    if (this.currentBrand === null) return
    await this.loadLeads()
    await this.chatStore.loadMessages()
  }
}
</script>

<style lang="scss" scoped>
#leads {
  width: calc(5 * 296px + 4 * 32px);
  max-width: 100%;
  margin-bottom: 32px;

  >.d-flex {
    gap: 32px;
    flex-wrap: wrap;
  }

  a {
    color: #00719f;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration: none;
  }

  .sorting-row {
    margin: 32px 0;
  }

}

.mb-zapier-logo {
  height: 40px;
  display: inline-block;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
