<template>
  <div class="membership-progress-bar" v-if="hasCurrentBrand">
    <div class="step" :class="computedClassesState1">
      <img src="../../assets/images/einrichtung/1-grundeinrichtung.png" />
      <span>Grundeinrichtung abgeschlossen</span>
    </div>
    <div class="line" :class="computedLineClassesState1"></div>
    <div class="step" :class="computedClassesState2">
      <img src="../../assets/images/einrichtung/2-pruefung.png" />
      <span>{{ computedStep2Text }}</span>
    </div>
    <div class="line" :class="computedLineClassesState2"></div>
    <div class="step" :class="computedClassesState3">
      <img src="../../assets/images/einrichtung/3-texte.png" />
      <span>{{ computedStep3Text }}</span>
    </div>
    <div class="line" :class="computedLineClassesState3"></div>
    <div class="step" :class="computedClassesState4">
      <img src="../../assets/images/einrichtung/4-kurzprofil.png" />
      <span>Gratis-Präsentation veröffentlicht</span>
    </div>
    <div class="line" :class="computedLineClassesState4"></div>
    <div class="step" :class="computedClassesState5">
      <img src="../../assets/images/einrichtung/5-mitgliedschaft.png" />
      <router-link to="/mitgliedschaft-buchen">Mitgliedschaft starten</router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MembershipProgressBanner',
  props: [],
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    },
    hasCurrentBrand () {
      return typeof this.currentBrand.BrandID !== 'undefined'
    },
    currentBrand () {
      const current = this.app.currentBrand || {}
      return current
    },
    computedTextsCompleted () {
      return this.currentBrand.SDStatus >= 5
    },
    computedClassesState1 () {
      return {
        done: true,
        active: false
      }
    },
    computedLineClassesState1 () {
      return {
        done: this.computedClassesState1.done
      }
    },
    computedClassesState2 () {
      return {
        done: this.currentBrand.SDStatus >= 4 && this.currentBrand.Membership > 1,
        active: this.currentBrand.SDStatus < 3 && this.currentBrand.Membership > 1
      }
    },
    computedLineClassesState2 () {
      return {
        done: this.computedClassesState2.done
      }
    },
    computedStep2Text () {
      if (!this.computedClassesState2.done) return 'Profil wird geprüft'
      return 'Profil wurde geprüft'
    },
    computedClassesState3 () {
      return {
        done: this.computedClassesState1.done && this.computedClassesState2.done && this.computedTextsCompleted,
        active: this.computedClassesState1.done && this.computedClassesState2.done && !this.computedTextsCompleted
      }
    },
    computedLineClassesState3 () {
      return {
        done: this.computedClassesState3.done,
        active: this.computedClassesState1.done && this.computedClassesState2.done && this.computedClassesState3.active
      }
    },
    computedStep3Text () {
      if (!this.computedClassesState3.done) return 'Texte werden geschrieben'
      return 'Texte wurden geschrieben'
    },
    computedClassesState4 () {
      const done = this.isOnline && this.computedTextsCompleted
      return {
        done,
        active: done && this.computedClassesState1.done && this.computedClassesState2.done && this.computedClassesState3.done && !this.isBrandReadyForActivation
      }
    },
    computedLineClassesState4 () {
      return {
        done: this.computedClassesState5.done
      }
    },
    computedClassesState5 () {
      return {
        done: this.isBrandReadyForActivation,
        active: this.computedClassesState1.done && this.computedClassesState2.done && this.computedClassesState3.done && this.computedClassesState4.done && this.isBrandReadyForActivation
      }
    },
    isOnline () {
      return this.currentBrand.Membership > 1
    },
    isBrandReadyForActivation () {
      return (
        (this.currentBrand.Membership === 2 || this.currentBrand.Membership === 3) &&
        (this.currentBrand.StatusID === 5 || this.currentBrand.StatusID === 4) &&
        this.currentBrand.DataQualityID === 4 &&
        this.currentBrand.PflichfeldStatusID === 1 &&
        this.computedTextsCompleted
      )
    }
  }
}

</script>

<style scoped>
.membership-progress-bar {
  display: flex;
  width: 100%;
  padding: 32px;

  margin-bottom: 32px;

  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
}

.step {
  max-width: 180px;
  display: flex;
  padding: 0px 8px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.step.active {
  opacity: 1 !important;
}

.step.active span {
  font-weight: bold;
}

.step:not(.done):not(.active) {
  opacity: 0.25;
  pointer-events: none;
  user-select: none;
}

.step a {
  color: #00719F;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-decoration-line: underline;
}

.step img {
  width: 64px;
  height: 64px;
}

.step span {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.line {
  width: 102px;
  height: 2px;
  background: #EFF1F4;
}

.line.done {
  background: #A4D82E;
}

svg {
  width: 98px;
  height: 0px;
  flex: 1 0 0;
  stroke-width: 2px;
  fill: #A4D82E;
}
</style>
