<template>
  <FairFieldEditor title="Die nächsten Schritte" :index="17" :is-modal-open.sync="isModalOpen" :loading="isLoading"
    @save="handleSave" buttonSaveText="Änderungen speichern" :saveDisabled="!computedIsValid" @toggle="toggleContent"
    :usePageStore="true" @update:isModalOpen="updateFormFields">

    <div class="quote-box">
      <div class="next-steps-headline">Ich bin startklar, mein eigenes Unternehmen zu gründen. Wie
        geht&apos;s
        weiter?</div>
      <div class="d-flex flex-column gap-2">
        <div>Das ist großartig! Der erste Schritt ist einfach. Du stellst eine Anfrage über das Formular
          auf dieser Seite!
        </div>
        <strong>So geht´s weiter:</strong>
        <div>Du registrierst dich und bekommst Zugriff auf ein ausführliches Infopaket mit detaillierten
          Informationen zum
          Konzept. </div>
        <div v-html="computedNextStepsHtml" />
        <div>Sende jetzt das Formular ab und lass uns gemeinsam ins Gespräch kommen!</div>
      </div>
    </div>

    <template v-slot:editButtonCaption>
      <div class="d-flex align-items-center gap-2">
        <i class="fa fa-pencil"></i>
        <span>Bearbeiten</span>
      </div>
    </template>
    <template v-slot:preview>
      <img class="img-fluid" src="@/assets/images/new-fair/6_NextSteps.png" />
    </template>

    <template v-slot:edit>

      <div class="quote-box">
        <div class="next-steps-headline">Ich bin startklar, mein eigenes Unternehmen zu gründen. Wie
          geht&apos;s
          weiter?</div>
        <div class="d-flex flex-column gap-2">
          <div>Das ist großartig! Der erste Schritt ist einfach. Du stellst eine Anfrage über das Formular
            auf dieser Seite!
          </div>
          <strong>So geht´s weiter:</strong>
          <div>Du registrierst dich und bekommst Zugriff auf ein ausführliches Infopaket mit detaillierten
            Informationen zum
            Konzept. </div>

          <!-- textarea-->
          <textarea class="form-control w-100 flex-grow-1 py-3" style="min-height: 200px;"
            v-model="formFields.value"></textarea>

          <div>Sende jetzt das Formular ab und lass uns gemeinsam ins Gespräch kommen!</div>
        </div>
      </div>
      <hr />

      <div class="d-flex flex-column w-100 gap-3">
        <div class="d-flex flex-column gap-1">
          <span class="fw-bold">Anforderungen:</span>
          <ul class="special">
            <li :class="{
              success: formFields.value.length <= 500,
              error: formFields.value.length > 500
            }">Der individualisierbare Teile darf max. 500 Zeichen haben</li>
            <li>Der Anfang und das Ende der Antwort ist fest vorgegeben. Passen Sie den mittleren Teil auf
              Ihren Rekrutierungsprozess an!</li>
            <li>Im Text soll dem Interessenten gezeigt werden, was nach seiner Infopaket-Anfrage passiert
            </li>
            <li>Der Text sollte positiv, freundlich und persönlich geschrieben sein</li>
            <li>Der Text soll zeigen, dass der Interessent nach der Anfrage durch den weiteren Prozess
              begleitet wird</li>
          </ul>
        </div>
      </div>

    </template>

  </FairFieldEditor>
</template>

<script>
import FairFieldEditor from '@/components/FairFieldEditor.vue'
import { useBrandStore } from '@/stores/brandStore'

export default {
  name: 'FairFormWhatYouGet',
  components: {
    FairFieldEditor
  },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      isEditing: false,
      isModalOpen: false,
      formFields: {
        placeholder: 'Tragen Sie hier Ihren neuen Vorschlag ein.',
        value: ''
      }
    }
  },
  methods: {
    updateFormFields () {
      this.formFields.value = this.computedNextSteps
    },
    toggleEdit () {
      this.isEditing = !this.isEditing
    },
    toggleContent () {
      this.isOpen = !this.isOpen
    },
    async handleSave () {
      this.isLoading = true
      try {
        await this.submitForm()
        this.$toast.success('Änderungen erfolgreich gespeichert.')
        this.isModalOpen = false
        this.formFields.value = ''
      } catch (error) {
        console.error('Error at Saving', error)
        this.$toast.error('Beim Speichern deiner Änderungen ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
      }
    },
    async submitForm () {
      const brandStore = useBrandStore()
      await brandStore.updateNextSteps(this.formFields.value)
    }
  },
  computed: {
    computedIsValid () {
      return this.formFields.value.length > 0 && this.formFields.value.length <= 500
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      return this.app.currentBrand || {}
    },
    computedShortBrandName () {
      return this.currentBrand.ShortBrandName ?? this.currentBrand.Name ?? ''
    },
    computedExpansionFormCaption () {
      if (this.currentBrand) {
        if (this.currentBrand.ConceptExpansionFormFranchise) return 'Franchise'
        if (this.currentBrand.ConceptExpansionFormLicense) return 'Lizenz'
      }
      return ''
    },
    computedNextSteps () {
      let NextStepsText = ''

      const brandStore = useBrandStore()
      const defaultFaq13 = brandStore.getDefaultFaqs.find(faq => faq.ExternalID === 13)
      const hasDefaultFaqWithId13 = brandStore.getBrandFaqs.find(faq => faq.ExternalID === 13)
      if (!hasDefaultFaqWithId13) {
        NextStepsText = defaultFaq13 ? defaultFaq13.Answer : ''
      } else {
        NextStepsText = hasDefaultFaqWithId13.Answer
      }

      return NextStepsText
    },
    computedNextStepsHtml () {
      const NextStepsText = this.computedNextSteps
      return String(NextStepsText)
        .replaceAll('\r\n', '<br />')
        .replaceAll('\n\n', '<br />')
        .replaceAll('\r', '<br />')
        .replaceAll('\n', '<br />')
    }
  },
  mounted: async function () {
  }

}
</script>
