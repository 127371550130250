<template>
  <div class="custom-radio-input">
    <b-form-radio-group v-model="currentSelection" @change="handleRadioChange">
      <b-form-radio value="isNull">
        {{ optionNames[0] }}
      </b-form-radio>
      <b-form-radio value="isUnlimited">
        {{ optionNames[1] }}
      </b-form-radio>
      <b-form-radio value="isOpenForInput">
        {{ optionNames[2] }}
      </b-form-radio>
    </b-form-radio-group>
    <div v-show="isShowInput" class="input-suffix-holder">
      <input v-model="currentInputValue" :type="inputType || 'text'" class="form-control" />
      <span class="suffix">{{ suffix }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomRadioInput',
  components: {},
  props: ['value', 'suffix', 'optionNames', 'inputType'],
  data: function () {
    return {
      currentSelection: 'isNull',
      isShowInput: false
    }
  },
  computed: {
    currentInputValue: {
      get: function () {
        return this.value + ''
      },
      set: function (newValue) {
        if (newValue !== '') newValue = newValue.replace('.', '')
        this.$emit('input', newValue)
        this.$emit('change')
      }
    },
    valueIsNull: function () {
      return this.currentInputValue === '-1'
    },
    valueIsUnlimited: function () {
      return this.currentInputValue === '0'
    },
    valueIsSet: function () {
      return !this.valueIsNull && !this.valueIsUnlimited
    }
  },
  watch: {
    value: function () {
      this.init()
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    init () {
      if (this.valueIsSet) {
        this.isShowInput = true
        this.currentSelection = 'isOpenForInput'
        return
      }

      if (this.valueIsUnlimited) {
        this.isShowInput = false
        this.currentSelection = 'isUnlimited'
        return
      }

      this.isShowInput = false
      this.currentSelection = 'isNull'
    },
    handleRadioChange (newRadioValue) {
      this.$emit('change')
      switch (newRadioValue) {
        case 'isOpenForInput':
          this.isShowInput = true
          this.currentInputValue = ''
          break
        case 'isUnlimited':
          this.isShowInput = false
          this.currentInputValue = '0'
          break
        case 'isNull':
          this.isShowInput = false
          this.currentInputValue = '-1'
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-suffix-holder {
  width: 230px;
  border: 1px solid #c2cfd6;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.input-suffix-holder input {
  width: 70%;
  border-top: none;
  border-left: none;
  border-bottom: none;
  border-right: 1px solid #c2cfd6;
}
.input-suffix-holder .suffix {
  width: 30%;
  text-align: center;
}
</style>
