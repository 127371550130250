<template>
  <FairFieldEditor title="Überschrift" :index="1" buttonSaveText="Korrekturwunsch absenden"
    :showContentOnEdit="true" :loading="isLoading" @save="handleSave" :isEditing="isEditing"
    :saveDisabled="!computedIsValid" :is-modal-open.sync="isModalOpen" @toggleEdit="toggleEdit" @toggle="toggleContent" :usePageStore="true"
    :showOpenButton="false" v-if="computedShowStep">
    <div class="d-flex flex-column fw-bold">
      <span><strong class="color-primary">{{ currentBrand.Name }}</strong> {{
        computedExpansionFormCaption }}:</span>
      <span>{{ currentFair.Title }}</span>
    </div>
    <template v-slot:edit>
      <hr />
      <div class="d-flex flex-column w-100 gap-3">
        <input type="text" class="form-control w-100" :placeholder="formFields.placeholder"
          v-model="formFields.value" />
        <div class="d-flex flex-column gap-1">
          <span class="fw-bold">Anforderungen:</span>
          <ul class="special">
            <li :class="{
              success: formFields.value.length <= 65,
              error: formFields.value.length > 65
            }">Die zweite Zeile darf max. 65 Zeichen haben, gerne etwas kürzer</li>
            <li>Die erste Zeile lautet immer “<em>{{ currentBrand.Name }} {{ computedExpansionFormCaption
                }}</em>:”</li>
            <li>Die Überschrift in der 2. Zeile sollte sich mit einem motivierenden Aufruf an den User wenden und idealerweise Vernunft und Emotionen gleichermaßen ansprechen</li>
            <li>Sie sollte seine künftige Tätigkeit und deren positive Folgen für Kunden zum Inhalt haben wie z.B. Biete / Kreiere / Begeistere / Unterstütze / Verwandle / Vermittle / Verwöhne / Sorge für ...</li>
            <li class="d-flex align-items-start inner-list">
              <div class="d-flex flex-column align-items-start gap-1">
                <strong>Beispiel:</strong>
                <span>“Kreiere Wohnwelten aus nachwachsenden Rohstoffen!”</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template v-slot:editButtonCaption>
      Stimmt etwas nicht?
    </template>
    <template v-slot:preview>
      <img class="img-fluid" src="@/assets/images/new-fair/1_Headline.png" />
    </template>
  </FairFieldEditor>
</template>

<script>
import FairFieldEditor from '@/components/FairFieldEditor.vue'
import { useBrandStore } from '@/stores/brandStore'
import { usePageStore } from '@/stores/pageStore'

export default {
  name: 'FairFormHeadline',
  components: {
    FairFieldEditor
  },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      isEditing: false,
      isModalOpen: false,
      formFields: {
        placeholder: 'Tragen Sie hier Ihren neuen Vorschlag ein.',
        value: ''
      }
    }
  },
  methods: {
    toggleEdit () {
      this.isEditing = !this.isEditing
    },
    toggleContent () {
      const pageStore = usePageStore()
      if (pageStore.getCurrentOpenedFairEditTab === 1) {
        pageStore.setCurrentOpenedFairEditTab(null)
      } else {
        pageStore.setCurrentOpenedFairEditTab(1)
      }
    },
    async handleSave () {
      this.isLoading = true
      try {
        await this.submitForm()
        this.$toast.success('Korrekturwünsche wurden erfolgreich gespeichert.')
        this.isModalOpen = false
        this.formFields.value = ''
      } catch (error) {
        console.error('Error at Saving', error)
        this.$toast.error('Beim Speichern deiner Korrekturwünsche ist ein Fehler aufgetreten.')
      } finally {
        this.isLoading = false
      }
    },
    async submitForm () {
      await this.app.APIService._post('mb/fair/update/1', {
        brandId: this.currentBrand.BrandID,
        value: this.formFields.value
      })
    }
  },
  computed: {
    computedShowStep () {
      const currentHeadline = this.currentFair.Title
      return currentHeadline !== null && currentHeadline !== '' && String(currentHeadline).length > 0
    },
    computedIsValid () {
      return this.formFields.value.length > 0 && this.formFields.value.length <= 65
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentBrand
    },
    currentFair: function () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentFair
    },
    computedExpansionFormCaption () {
      if (this.currentBrand) {
        if (this.currentBrand.ConceptExpansionFormFranchise) return 'Franchise'
        if (this.currentBrand.ConceptExpansionFormLicense) return 'Lizenz'
      }
      return ''
    },
    computedCurrentIsOpen () {
      const pageStore = usePageStore()
      return pageStore.getCurrentOpenedFairEditTab === 1
    }
  }

}
</script>
