<template>
  <section>
    <div v-if="!computedIsNextStepFaq" class="d-flex justify-content-between align-items-center">
      <div class="d-flex gap-2 align-items-center">
        <span>Reihenfolge:</span>
        <button class="btn btn-secondary" :class="{
          'disabled': isFirst
        }" @click="sortOrderUp">
          <i class="fa fa-arrow-up"></i>
        </button>
        <button class="btn btn-secondary" :class="{
          'disabled': isLast
        }" @click="sortOrderDown">
          <i class="fa fa-arrow-down"></i>
        </button>
      </div>
      <button class="btn btn-secondary" @click="removeFaq">
        <i class="fa fa-trash"></i>
        <span>Entfernen</span>
      </button>
    </div>
    <div v-if="!computedIsNextStepFaq">
      <input type="text" class="form-control" v-model="faq.Question" />
    </div>
    <div v-if="!computedIsNextStepFaq">
      <textarea class="form-control" v-model="faq.Answer" />
    </div>

    <div v-if="computedIsNextStepFaq" class="d-flex justify-content-between align-items-center">
      <div class="d-flex gap-2 align-items-center">
        <span class="text-muted">Reihenfolge ist fixiert</span>
      </div>
      <button class="btn btn-secondary" disabled="disabled" @click="removeFaq">
        <i class="fa fa-trash"></i>
        <span>Kann nicht entfernt werden</span>
      </button>
    </div>
    <div v-if="computedIsNextStepFaq">
      <div class="next-steps-headline">Ich bin startklar, mein eigenes Unternehmen zu gründen. Wie
        geht&apos;s
        weiter?</div>
      <div class="d-flex flex-column gap-2">
        <div>Das ist großartig! Der erste Schritt ist einfach. Du stellst eine Anfrage über das Formular
          auf dieser Seite!
        </div>
        <strong>So geht´s weiter:</strong>
        <div>Du registrierst dich und bekommst Zugriff auf ein ausführliches Infopaket mit detaillierten
          Informationen zum
          Konzept. </div>

        <!-- textarea-->
        <textarea class="form-control w-100 flex-grow-1 py-3" style="min-height: 200px;"
          v-model="faq.Answer"></textarea>

        <div>Sende jetzt das Formular ab und lass uns gemeinsam ins Gespräch kommen!</div>
      </div>
    </div>
  </section>
</template>

<script>
import { useBrandStore } from '@/stores/brandStore'

export default {
  name: 'FaqQuestionContainer',
  data: function () {
    return {
    }
  },
  props: {
    index: {
      type: Number,
      default: 1,
      required: false
    },
    faq: {
      type: Object,
      required: true
    },
    isFirst: {
      type: Boolean,
      default: false
    },
    isLast: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentBrand () {
      const brandStore = useBrandStore()
      return brandStore.getCurrentBrand
    },
    computedExpansionFormCaption () {
      if (this.currentBrand) {
        if (this.currentBrand.ConceptExpansionFormFranchise) return 'Franchise'
        if (this.currentBrand.ConceptExpansionFormLicense) return 'Lizenz'
      }
      return ''
    },
    computedIsNextStepFaq () {
      return this.faq.ExternalID === 13
    }
  },
  methods: {
    sortOrderUp () {
      this.$emit('sortOrderUp', this.faq.__localId)
    },
    sortOrderDown () {
      this.$emit('sortOrderDown', this.faq.__localId)
    },
    removeFaq () {
      this.$emit('removeFaq', this.faq.__localId)
    },
    rewrittenQuestion (question) {
      return String(question.Question ?? '')
        .replaceAll('{{EXPANSIONSFORM}}', this.computedExpansionFormCaption)
        .replaceAll('{{MARKE}}', this.currentBrand.Name)
    },
    rewrittenAnswer (answer) {
      return String(answer.Answer ?? '')
        .replaceAll('{{EXPANSIONSFORM}}', this.computedExpansionFormCaption)
        .replaceAll('{{MARKE}}', this.currentBrand.Name)
    }
  },
  mounted () {
    this.faq.Question = this.rewrittenQuestion(this.faq)
    this.faq.Answer = this.rewrittenAnswer(this.faq)
  }
}

</script>

<style lang="scss" scoped>
section {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background-color: #EFF1F4;

  a {
    color: #3F4957;
    text-decoration: none;
  }

  >div {
    width: 100%;

    textarea {
      height: 180px;
      padding: 8px 12px;
    }
  }

}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  flex-grow: 1;

  .headline {
    flex: 1 0 0;
    color: #3F4957;
    font-family: "Nunito Sans";
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
}
</style>
