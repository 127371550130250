<template>
  <footer class="mb-login-footer">
    <div v-if="showKMFooter === true" class="mb-km-footer">
      <table>
        <tr>
          <td style="width:230px"><img
              :src="'https://www.franchiseportal.de/api/drive/' + kmContact.Portrait.URL.replace('.jpg', '.png') + '?size=sm&format=webp'"
              style="width:230px"></td>
          <td style="color: #fff">
            <div>
              <h2 v-t="'footer.contactperson'"></h2>
              <h3>{{ kmContact.Firstname }} {{ kmContact.Lastname }}</h3>
            </div>
            <hr />
            <table style="color: #fff">
              <tr>
                <td style="padding-bottom: 5px"><img src="https://www.franchiseportal.de/images/mail/telephone.png"
                    style="width: 25px; height: 25px"></td>
                <td style="padding-left: 5px;padding-bottom: 5px">{{ kmContact.Phone }}</td>
              </tr>
              <tr>
                <td style="padding-bottom: 5px"><img src="https://www.franchiseportal.de/images/mail/email.png"
                    style="width: 25px; height: 25px"></td>
                <td style="padding-left: 5px;padding-bottom: 5px">{{ kmContact.Mail }}</td>
              </tr>
              <tr>
                <td><img src="https://www.franchiseportal.de/images/mail/calendar.png"
                    style="width: 25px; height: 25px"></td>
                <td style="padding-left: 5px;"><a style="color: #fff" :href="kmContact.Street" target="_blank">{{
                  $t('footer.telephoneappointment') }}</a></td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
    <div class="container">
      <div class="mb-logo">
        <img src="../assets/images/fp-logo-monochrom@2x.png" alt="FranchisePORTAL">
      </div>
      <div class="mb-footer-text" v-t="'footer.leadinginfranchise'"></div>
      <div class="container mb-footer-cont">
        <a href="https://www.franchiseportal.de/datenschutz" target="_blank" v-t="'footer.dataprotection'"></a>
        <a href="https://www.franchiseportal.de/impressum" target="_blank" v-t="'footer.imprint'"></a>
        <a href="https://www.franchiseportal.de/agb" target="_blank" v-t="'footer.agb'"></a>
      </div>
      <div class="mb-copyright">
        <div>COPYRIGHT © {{ currentYear }} FranchisePORTAL GmbH</div>
        <div v-t="'footer.copyrightrights'"></div>
      </div>
      <p class="text-center mb-vers-cont">
        <small class="clientVersionHint">v{{ currentAppVersion }}</small>
      </p>
    </div>
  </footer>
</template>

<script>
import Config from '../config'
export default {
  name: 'Footer',
  props: [],
  data: function () {
    return {
      showKMFooter: false,
      kmContact: {}
    }
  },
  computed: {
    currentYear: () => {
      return new Date().getFullYear()
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand () {
      const current = this.app.currentBrand || {}
      return current
    },
    currentAppVersion: function () {
      return Config.CLIENT_VERSION
    }
  },
  methods: {
    getKMContact () {
      if (this.currentBrand == null || this.currentBrand.FPBrandManager == null) {
        this.showKMFooter = false
        return
      }
      const contactID = this.getKMContactID(this.currentBrand.FPBrandManager)
      this.kmContact = this.app.getContactByID(contactID)
        .then(response => {
          this.kmContact = response.body.Result.Data
          this.showKMFooter = true
        })
    },
    getKMContactID (kmKuerzel) {
      let contactID = 0
      switch (kmKuerzel) {
        case 'PTI':
          contactID = 81721
          break
        case 'FLI':
          contactID = 39642
          break
        case 'AHO':
          contactID = 41996
          break
      }

      return contactID
    }
  },
  mounted: function () {
  },
  watch: {
    'currentBrand.BrandID': function () {
      this.getKMContact()
    }
  }
}
</script>
