<template>
  <div>
    <div v-if="app.hasCurrentBrand" id="broschuere" class="container mb-info-konzept p-0">
      <div class="card mb-5">
        <div class="card-body">
          <form>
            <h5 class="card-title" v-t="'page.yourdata.brochure.title'"></h5>
            <p v-t="'page.yourdata.brochure.description'"></p>
            <div class="d-flex flex-column gap-3">
              <div class="d-flex flex-column gap-2 w-100" v-if="infoBrochureID != null">
                <div class="d-flex flex-column">
                  <div class="font-weight-bold" v-t="'page.yourdata.brochure.forapplicant'"></div>
                  <a :href="currentBroschureLink" class="card-link" target="_blank">{{ currentBroschureLink }}</a>
                </div>
                <div class="d-flex gap-2">
                  <a :href="currentBroschureLink" target="_blank" class="btn btn-success" v-t="'button.download'"></a>
                  <button type="button" class="btn btn-danger" v-t="'button.delete'" @click="deleteDocument"></button>
                </div>
              </div>
              <div class="custom-file d-flex align-items-center gap-2">
                <div class="font-weight-bold" v-t="'page.yourdata.brochure.upload'"></div>
                <label class="btn custom-file-label" for="customFile" v-t="'page.yourdata.brochure.select'"></label>
                <input id="customFile" type="file" class="custom-file-input d-none" @change="handleDocumentUpload">
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Config from '../../../config'
import axios from 'axios'
export default {
  name: 'BrandProfileDataDocument',
  props: [],
  data: function () {
    return {
      loadedMedium: false
    }
  },
  computed: {
    currentBroschureLink: function () {
      if (this.loadedMedium === false) return false
      return [this.imageDriveURL, 'id/', this.loadedMedium.FileID].join('')
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      return this.app.currentBrand || {}
    },
    preloads: function () {
      return this.app.preloads || {}
    },
    messestand: function () {
      return this.app.messestand || {}
    },
    imageDriveURL () {
      return Config.MEDIA_API_URL
    },
    infoBrochureID: function () {
      return this.messestand.InfoBrochureID
    }
  },
  watch: {
    'app.hasCurrentBrand': function () {
      this.loadData()
    }
  },
  mounted: function () {
    if (this.app.hasCurrentBrand) {
      this.loadedMedium = false
      this.loadData()
    }
  },
  methods: {
    deleteDocument () {
      this.$swal.fire({
        title: this.$t('popupmessage.warn.areyousuretitle'),
        text: this.$t('popupmessage.warn.deletebrochure'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no')
      }).then(result => {
        if (
          typeof result.value !== 'undefined' &&
          result.value === true
        ) {
          Promise.all([this.app.getMedium(this.messestand.InfoBrochureID)])
            .then((response) => {
              const medium = response[0]
              this.messestand.InfoBrochureID = null
              const dataToSave = {
                FairID: this.messestand.FairID,
                NullFields: ['InfoBrochureID']
              }
              this.app.API._put('fair/', dataToSave)
                .then((response) => {
                })

              this.app.APIMedia._delete(`id/${medium.FileID}`).then(response => {
                this.app.API._delete('medium/' + medium.MediumID).then((response) => {
                })
              })
            })
        }
      })
    },
    handleDocumentUpload (event) {
      if (event.target.files.length === 0) return
      const file = event.target.files[0]

      if (file.type === 'application/pdf') {
        const loader = this.app.startLoader()

        const uploadDocumentData = new FormData()
        uploadDocumentData.append('file', file)
        uploadDocumentData.append('folder', 'SystemRoot/Infobroschueren PDF')
        uploadDocumentData.append('overwriteMode', 1)
        uploadDocumentData.append('identifier', -1)

        const loginFromStorage = localStorage.getItem('FPMB_Login')
        const login = loginFromStorage ? JSON.parse(loginFromStorage) : {}

        axios
          .post(this.imageDriveURL, uploadDocumentData, {
            headers: {
              Accept: 'application/json',
              Authorization: ['Bearer', login.token].join(' '),
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            const error = response.data.Error !== 0
            if (error) return

            const uploadedFileData = response.data.Data.shift()

            this.app.API._put('medium/', {
              MediumID: -1,
              FileID: uploadedFileData.identifier,
              Filename: uploadedFileData.fullPath,
              FolderID: 64,
              Hash: uploadedFileData.hash,
              Height: uploadedFileData.mediainfo.height,
              Size: uploadedFileData.size,
              State: 0,
              TypeID: 1,
              Width: uploadedFileData.mediainfo.width
            }).then(response => {
              this.messestand.InfoBrochureID = response.body.Result.NewID
              new Promise((resolve, reject) => {
                this.app.API._put('fair/', {
                  FairID: this.messestand.FairID,
                  InfoBrochureID: this.messestand.InfoBrochureID
                })
                  .then(resolve)
                  .catch(reject)
              })
                .then(() => {
                  this.$swal(
                    this.$t('popupmessage.success.savedtitle'),
                    'Die neue Unternehmensbroschüre wurde hochgeladen und gespeichert.',
                    'success'
                  )
                  this.loadData()
                })
                .finally(loader.hide)
            })
          })
      } else {
        this.$swal(
          'Fehler',
          'Es sind nur PDF-Dateien zum Upload erlaubt.',
          'error'
        )
      }
    },
    loadData () {
      if (
        typeof this.messestand.InfoBrochureID === 'undefined' ||
        this.messestand.InfoBrochureID === null
      ) { return (this.loadedMedium = false) }
      const loadOptions = {
        ResFrom: 1,
        ResTo: 1,
        FullSubEntities: true,
        Filter: [
          {
            Field: 'MediumID',
            Operator: 'EQ',
            Value: this.messestand.InfoBrochureID
          }
        ]
      }
      this.app.API._post('media/', loadOptions).then(response => {
        this.loadedMedium = response.body.Result.Data.shift()
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
